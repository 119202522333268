<template>
  <div class="Insights">
    <div class="banner">
      <img width="100%" src="../assets/banner3.png" alt="" />
      <div class="title">
        <div class="h1">INSIGHTS</div>
      </div>
    </div>

    <div class="main">
      <div class="minmain">
        <div class="left">Featured Insights</div>
        <div class="right">
          <router-link to="/FeaturedInsights"
            >Read More
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xiayibu"></use></svg
          ></router-link>
        </div>
      </div>
      <div class="FeaturedInsight">
        <div
          class="content"
          v-for="(item, index) in FeaturedInsight"
          :key="index"
          @click="handleClick(item.articleid)"
        >
          <div class="headline">
            <!-- //:class="`headline${index}`" -->
            {{ item.subject }}
          </div>
          <div class="time">{{ "" }}</div>
          <!-- item.datecreated.substring(0, 10) -->
          <!-- <div class="headline" style="width: 45%">
            2.Insurance+Trust=Insurance Trust, Another Preferred Choice For The
            Rich
          </div>
          <div class="time">2021-04-12</div>
          <div class="headline" style="width: 45%">
            3.Retirement & Relief Trust : Your Anchor Of Happy Retirement
          </div>
          <div class="time">2021-03-02</div>
          <div class="headline" style="width: 45%">
            4.Customized Wealth Management At Different Stages Of Life
          </div>
          <div class="time2">2021-02-01</div> -->
        </div>
        <div class="banner2">
          <img width="100%" height="100%" src="../assets/Insughts.png" alt="" />
        </div>
      </div>
      <div class="minmain">
        <div class="left" style="margin-top: 7px">Market Information</div>
        <div class="right">
          <router-link to="/MarketInformation">
            Read More
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xiayibu"></use>
            </svg>
          </router-link>
        </div>
      </div>
      <div class="MarketInformation">
        <div
          class="MarketInformationContent"
          v-for="(item, index) in MarketInformation"
          :key="index"
          @click="handleClick(item.articleid)"
        >
          <img width="100%" :src="item.full_path_surface_plot" />
          <div class="text">{{ item.subject }}</div>
          <!-- <div class="time">{{ item.datecreated.substring(0, 10) }}</div> -->
        </div>
      </div>
      <div class="minmain">
        <div class="left">Recommendation Column</div>
        <div class="right">
          <router-link to="/RecommendationColumn">
            Read More
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-xiayibu"></use>
            </svg>
          </router-link>
        </div>
      </div>
      <div class="RecommendationColumn">
        <div
          class="RecommendationColumnContent"
          v-for="(item, index) in RecommendationColumn"
          :key="index"
          @click="handleClick(item.articleid)"
        >
          <img width="100%" :src="item.full_path_surface_plot" />
          <div class="text">{{ item.subject }}</div>
          <!-- <div class="time">{{ item.datecreated.substring(0, 10) }}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "../js/http";
// import axios from "axios";
export default {
  data() {
    return {
      MarketInformation: [],
      RecommendationColumn: [],
      FeaturedInsight: [],
    };
  },
  methods: {
    handleClick(id) {
      this.$router.push(`/Article?id=${id}`);
      /* this.$router.push({
        name: "Article",
        params: {
          myid: id,
        },
      }); */
    },
  },
  created() {
    /* this.$axios.get("api").then((res) => {
      console.log(res);
    }); */
    /* axios
      .get("www.trusthongkong.com/hkt/hkt/Information/news_list?lang=en")
      .then((res) => {
        console.log(res);
      }); */
    http({
      url: "/hkt/hkt/Information/news_list?lang=en",
    }).then((res) => {
      /* console.log(res); */
      this.MarketInformation = res.data.data.market_information;
      this.RecommendationColumn = res.data.data.choiceness_column;
      this.FeaturedInsight = res.data.data.choiceness_article;
    });
  },
};
</script>
<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 20px;
  color: rgb(0, 0, 0);
  margin-left: 5px;
}
/* .before {
  width: 100vw;
  height: 994px;
  background: #000;
  opacity: 0.5;
  position: absolute;
  top: 0px;
} */
.banner {
  width: 100vw;
  position: relative;
}
.title {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  /* z-index: 100; */
  position: absolute;
  top: 45%;
  .h1 {
    font-size: 55px;
    color: #fff;
  }
}
.main {
  width: 60vw;
  margin: 0px auto;
  text-align: left;
}
.minmain {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid #000;
  color: #000;
  font-weight: 500;
  .left {
    font-size: 26px;
  }
  .right {
    font-size: 20px;
  }
}
.headline {
  margin-top: 25px;
  width: 90%;
  font-size: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* color: #000; */
}
/* .headline1 {
  width: 45%;
}
.headline2 {
  width: 45%;
} */
.time {
  width: 90%;
  font-size: 17px;
  padding-bottom: 30px;
  border-bottom: 1px solid #000;
  color: #000;
}
.time2 {
  width: 45%;
  font-size: 30px;
  padding-bottom: 40px;
  color: #000;
}
.FeaturedInsight {
  position: relative;
  .content {
    width: 50%;
    font-family: contert;
  }
  .content:hover {
    color: #a95228;
    img {
      transform: scale(1.1);
      transition: all 0.6s;
    }
    .time {
      color: #a95228;
    }
  }
  .banner2 {
    width: 50%;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
  }
}
.MarketInformation {
  display: flex;
  margin-top: 60px;
  justify-content: space-between;
  .MarketInformationContent {
    width: 31%;
    font-family: contert;
    overflow: hidden;
  }
  .MarketInformationContent:hover {
    color: #a95228;
    img {
      transform: scale(1.1);
      transition: all 0.6s;
    }
    .time {
      color: #a95228;
    }
  }
  .text {
    margin-top: 25px;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .time {
    margin-top: 40px;
    width: 100%;
    font-size: 17px;
    border: none;
    padding-bottom: 0px;
    color: #000;
  }
}
.RecommendationColumn {
  display: flex;
  margin-top: 60px;
  justify-content: space-between;
  .RecommendationColumnContent {
    width: 31%;
    font-family: contert;
    overflow: hidden;
  }
  .RecommendationColumnContent:hover {
    color: #a95228;
    img {
      transform: scale(1.1);
      transition: all 0.6s;
    }
    .time {
      color: #a95228;
    }
  }
  .text {
    margin-top: 25px;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .time {
    margin-top: 40px;
    width: 100%;
    font-size: 17px;
    border: none;
    padding-bottom: 0px;
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .main {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title .h1 {
    font-size: 36px;
  }
  .icon {
    font-size: 16px;
  }
  .main {
    width: 80vw;
    .minmain {
      padding-top: 50px;
      .left {
        font-size: 20px;
        font-weight: 600;
      }
      .right {
        font-size: 16px;
      }
    }
  }
  .headline {
    font-size: 18px;
  }
  .MarketInformation {
    .text {
      font-size: 16px;
      margin-top: 5px;
    }
    .time {
      margin-top: 20px;
      font-size: 14px;
    }
  }
  .RecommendationColumn {
    margin-bottom: 100px;
    .text {
      font-size: 16px;
      margin-top: 5px;
    }
    .time {
      margin-top: 20px;
      font-size: 14px;
    }
  }
}
@media (max-width: 767px) {
  // 手机背景图
  // .banner img {
  //   height: 520px;
  //   object-fit: cover;
  // }
  .title {
    top: 35%;
    .h1 {
      font-size: 30px;
    }
  }
  .banner {
    margin-top: 64px;
  }
  .main {
    width: 78vw;
    .minmain {
      margin-top: 20px;
      padding-top: 0px;
      padding-bottom: 10px;
      .left {
        font-size: 16px;
        font-weight: 500;
      }
      .right {
        font-size: 14px;
        font-weight: 500;
        .icon {
          font-size: 14px;
        }
      }
    }
  }
  .FeaturedInsight {
    .content {
      width: 100%;
      .headline {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .time {
        font-size: 12px;
        padding-bottom: 12px;
        width: 100%;
      }
    }
    .banner2 {
      display: none;
    }
  }
  .MarketInformation {
    display: block;
    margin-top: 0px;
    .MarketInformationContent {
      width: 100%;
      margin-top: 10px;
    }
    .text {
      font-size: 16px;
      margin-top: 0px;
    }
    .time {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .RecommendationColumn {
    display: block;
    margin-top: 0px;
    margin-bottom: 20px;
    .RecommendationColumnContent {
      width: 100%;
      margin-top: 10px;

      .text {
        margin-top: 0px;
        font-size: 16px;
      }
      .time {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}
</style>
